import React from 'react'
import BlockContent from './block-content'
import Container from './container'

import styles from './project.module.css'

function About (props) {
  const {about} = props
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 hidden className={styles.title}>{about.edges[0].node.title}</h1>
            {about && <BlockContent blocks={about.edges[0].node._rawBody || []} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default About
