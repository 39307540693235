import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from '../lib/helpers'

import {responsiveTitle1} from '../components/typography.module.css'
import About from '../components/about'

export const query = graphql`
  query AboutPageQuery {
    about: allSanityAbout(
      limit:1
    ) {
      edges {
        node {
          id
          title
          _rawBody
          }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = (data || {}).about
  return (
    <Layout>
      <SEO title='About' />
      <Container>
        <About about={about} _rawBody={about._rawBody} />
      </Container>
    </Layout>
  )
}

export default AboutPage
